<template>
  <div v-if="loadingApp">
    <div class="loader-dashboard-app"><div></div></div>
    <vue-snotify></vue-snotify>
  </div>

  <div id="app-vue" v-else>
    <div v-if="testVersion" style="height: 16px; z-index: 9999999">
      <div class="test-top-bar">test version: {{ $store.state.config.appVersion }}</div>
    </div>
    <div class="browser-warning price-warning" v-if="browserTooOld">
      Your browser is not supported by this application. Please consider using a modern browser such as
      <a target="_blank" href="https://www.google.com/chrome/">Chrome</a>,
      <a target="_blank" href="https://www.mozilla.org/en-US/firefox/new/">FireFox</a>,
      <a target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>.
    </div>

    <top-bar v-if="!unauthenticatedPages()" />

    <div class="container-wrapper-custom">
      <router-view class="router-view"></router-view>
    </div>

    <vue-snotify></vue-snotify>

    <div v-if="$store.state.transLoader" class="loader-full-screen transparent in">
      <div class="loader" id="loader-1"></div>
    </div>
    <CheckForLatestVersion />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as helpers from './helpers/functions.js';
import bowser from 'bowser';
import '@/assets/styles/main.scss';
import TopBar from '@/components/generic/top-bar.vue';
import CheckForLatestVersion from '@/components/generic/CheckForLatestVersion.vue';

export default {
  name: 'app',
  data() {
    return {
      userEmailLocal: null,
      browserTooOld: false,
      loadingApp: true,
      testVersion: (process.env.VUE_APP_VERSION_NAME ?? null) == 'staging' ? true : false
    };
  },
  components: {
    TopBar,
    CheckForLatestVersion
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
      getBrowserData: 'getBrowserData',
      orderStates: 'orderStates'
    })
  },
  watch: {
    userData: {
      handler(n) {
        if (n) {
          this.loadingApp = false;
        }
      },
      immediate: true
    }
  },
  created: function() {
    if (process.env.VUE_APP_VERSION) console.log(process.env.VUE_APP_VERSION);
    helpers.handleInternetConnectivity();
    if (localStorage.getItem('my_lab_logged_in') == 'true' && !this.unauthenticatedPages()) {
      this.getUserDataLocal();
      //set browser data
      this.setBrowserData(bowser);
      this.setPrestineInitialData();
      this.getOrdersStatesCount();
      if (this.orderStates.length == 0) this.getOrderStates();
    } else {
      if (!this.unauthenticatedPages()) this.$router.push({ name: 'Login' });
      this.loadingApp = false;
    }
  },
  methods: {
    ...mapActions({
      getUserData: 'getUserData',
      getOrdersMade: 'getOrdersMade',
      getOrdersMadeUser: 'getOrdersMadeUser',
      getOrdersStatesCount: 'getOrdersStatesCount',
      getOrderStates: 'getOrderStates'
    }),
    ...mapMutations({
      setBrowserData: 'setBrowserData',
      setPrestineInitialData: 'setPrestineInitialData'
    }),
    getUserDataLocal() {
      if (Object.keys(this.userData).length === 0 && this.userData.constructor === Object)
        this.getUserData()
          .then(() => {
            setTimeout(() => {
              this.browserCheck();
            }, 100);
          })
          .catch(error => {
            console.log(error);
            helpers.displayError(error + ': user data', 'getUserDataLocal', this);
          });
    },
    browserCheck() {
      if (this.getBrowserData.name === 'Internet Explorer' && this.getBrowserData.version < 10) {
        this.browserTooOld = true;
      }
    },
    redirectToOrderIfUrlParam(orderNumber, isClient) {
      this.loadingApp = true;
      this.$store.commit('setSearchFilterQuery', orderNumber);
      this.$store.commit('selectOrderFromCalendar', true);
      if (!isClient) {
        this.getOrdersMade().then(order => {
          this.loadingApp = false;
          this.$store.commit('setSearchFilterQuery', '');
          this.$router.push('/orders/' + orderNumber);
          //this.$store.dispatch('getorderResults', order.results[0].id);
          this.$store.commit('selectOrderMade', order.results[0]);
        });
      } else {
      }
    },
    unauthenticatedPages() {
      const currentRouteName = window.location.pathname;
      const allowedPaths = ['/register', '/forgot-password', '/login', '/account-activation', '/reset-password'];
      if (allowedPaths.includes(currentRouteName)) return true;
      else return false;
    }
  }
};
</script>

<style lang="scss">
.test-top-bar {
  background: #ff8c20f2;
  color: white;
  height: 16px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 9999999;
}
.container-wrapper-custom {
  margin: 0px auto 0;
  max-width: 1200px;
  .router-view {
    overflow: hidden;
    margin: 0 30px;
    // display: flex;
    // align-items: flex-start;
  }

  .region-branding-area {
    overflow: hidden;
    margin: 0 30px;
    display: flex;
    justify-content: space-between;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .container-wrapper-custom {
    .router-view {
      margin: 0px 0px;
      flex-direction: column;
    }
  }
}
</style>
