export default {
  transLoader: false,
  orderPackages: [],
  orderData: {
    orderTotalPrice: null,
    orderTotalPriceAdminCosts: null,
    orderTotalPriceBBO: null,
    orderTotalPriceBAZV: null,
    orderTotalPriceWithTaxes: null,
    orderInvoiceDate: { normal: null, formatted: null },
    orderInvoiceDueDays: null,
    orderInvoiceDueDate: null,
    selectedClientIdByAdmin: null,
    adminCosts: null,
    additionalCosts: [{ description: null, hours: null, hourRate: null, cost: null }],
    additionalCostsTotal: null,
    stateChangeMail: true,
    sentEmailAssWorker: true,
    clientRejectedDateTime: false,
    orderComment: null
  },
  //ordersMadeUser: null,
  ordersMade: null,
  ordersMadeCustom: [],
  ordersStateCount: null,
  ordersMadeSelected: {},
  ordersMadeSelectedCached: {},
  ordersMadeSelectedData: {
    edit: false,
    editAccounting: false,
    orderId: null,
    selectedFromCalendar: false
  },
  ordersMadeFilters: {
    searchQuery: '',
    status: ''
  },
  orderStates: [],
  ordersExport: {
    orderListExport: [],
    orderListExportHeaders: []
  },
  termsAndConditions: [],
  auth: {
    userData: {},
    accessToken: null
  },
  config: {
    baseUrlApi: process.env.VUE_APP_BASE_URL_API,
    baseUrlContentSite: process.env.VUE_APP_BASE_URL_CONTENT_SITE,
    maxFileSize: 5000000, //5mb
    siteName: 'Online Laboratory Platform',
    labDepartment: 'WEB N.V. Lab department',
    supportTel: '+297 525 4663',
    appVersion: process.env.NODE_ENV == 'development' ? 'dev' : process.env.VUE_APP_VERSION
  },
  currency: 'Afl. ',
  packages: [],
  currentOrder: {
    selectedPackage: {},
    packageItems: [],
    packageSpecialRequests: null,
    packageItemTotals: [],
    differentAddressSampling: null,
    serviceAddress: null,
    samplePoint: null,
    sampleDoneByWebLab: null,
    packageSpecialRequests: null,
    name: null,
    duplicateOf: null,
    selectAll: [true, false]
  },
  currentStep: 1,
  wizardNav: {
    backButtonEnabled: false,
    backButtonText: '<i class="glyphicon glyphicon-chevron-left"></i> Back',
    nextButtonEnabled: false,
    nextButtonText: 'Next <i class="glyphicon glyphicon-chevron-right"></i>',
    animationClass: 'wizard-forward'
  },
  edit: {
    mode: false,
    index: null
  },
  pager: {
    currentPage: 1,
    pagerLinks: null,
    pagerDisplayCount: 15,
    animationClass: 'fade'
  },
  taxes: {
    bbo: 1.5,
    bazv: 3,
    bavp: 1.5
  },
  measurementUnits: null,
  resultsFile: { fileName: null, file: null },
  invoiceData: {
    companyName: null,
    companyWebsite: null,
    contactPerson: null,
    contactPersonTitle: null,
    contactPersonTelephone: null,
    contactPersonEmail: null,
    accountingEmail: null,
    bankAccount: null
  },
  browser: {
    name: null,
    version: null
  },
  cachedData: {
    currentOrder: null
  },
  dashboardAdmins: []
};
