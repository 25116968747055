<template>
  <div class="container-wrapper-custom topbar-wrapper">
    <div class="region region-branding-area" style="overflow: visible">
      <div
        id="block-weblab-branding"
        class="block block-system block-system-branding-block topbar-branding-wrapper"
        style="display: flex; justify-content: space-between; width: 100%"
      >
        <div>
          <a href="#" @click.prevent="goToHome()" title="Home" rel="home" class="site-logo">
            <img :src="require('@/assets/img/logos/logo-web-nv.svg')" alt="Home" />
          </a>
          <div class="site-slogan">
            Online <br />
            laboratory <br />
            platform
          </div>
        </div>

        <!-- DROPDOWN MENU USER -->
        <div class="pull-right user-btn-wrapper" :style="isDashboardAdmin ? 'margin-top: 15px' : 'margin-top: 25px'">
          <div class="dropdown">
            <a class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" href="#">
              <i class="glyphicon glyphicon-user"></i>
              {{ userData.first_name }} {{ userData.last_name }}
            </a>

            <div class="mr-1 ml-1">
              <span v-if="isDashboardAdmin" class="user-type-indicator mr-1 ml-1">Lab Admin</span>
              <span v-if="isAccounting" class="user-type-indicator ml-1 mb-2">Accounting</span>
              <span v-if="isAdmin" class="user-type-indicator admin ml-1">Admin</span>
            </div>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li v-if="$router.currentRoute.path != '/user-not-verified'">
                <a href="#" @click.prevent="$router.push('/user')" style="margin-left: 10px">
                  <i class="glyphicon glyphicon-user"></i>
                  User
                </a>
              </li>
              <li>
                <a href="https://lab.webaruba.com/contact" style="margin-left: 10px" target="_blank">
                  <i class="glyphicon glyphicon-earphone"></i>
                  Contact
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="logout()" class="" style="margin-left: 10px">
                  <i class="glyphicon glyphicon-log-out"></i>
                  Logout
                </a>
              </li>
              <hr class="mt-1 mb-1" style="border-color: #efefef" />
              <div class="mr-0" style="text-align: center; font-size: 12px; text-transform: uppercase; color: #0084b6">
                {{ $store.state.config.appVersion }}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      userData: 'userData',
      isDashboardAdmin: 'isDashboardAdmin',
      isAdmin: 'isAdmin',
      isAccounting: 'isAccounting'
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT').then(() => this.$router.push({ name: 'Login' }));
    },
    goToHome() {
      if (this.$router.currentRoute.path != '/user-not-verified') this.$router.push({ name: 'Home' }).catch(() => console.log('router catch'));
    }
  }
};
</script>

<style lang="scss">
.topbar-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  .topbar-branding-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>
