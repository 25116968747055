import { numberPad } from '../helpers/functions.js';

//app level
export const setPrestineInitialData = state => {
  state.cachedData.currentOrder = JSON.parse(JSON.stringify(state.currentOrder));
  //state.cachedData.orderPackages = JSON.parse(JSON.stringify(state.orderPackages));
};

export const setTransLoader = (state, data) => {
  state.transLoader = data;
};

//package data -------------------
export const setPackages = (state, packagesData) => {
  state.packages = packagesData;
};
export const selectPackage = (state, data) => {
  state.currentOrder.selectedPackage = {};
  state.currentOrder.packageItems = [];
  let packages = state.packages;
  packages.forEach(function(packageItem) {
    if (packageItem.package_code === data) state.currentOrder.selectedPackage = packageItem;
    return;
  });
};
export const unselectPackage = (state, data) => {
  state.currentOrder.selectedPackage = {};
  state.currentOrder.packageItems = [];
};
export const setPackageItems = (state, packageItems) => {
  let standardItems = setQuantityToItems(packageItems[0], true);
  let extraItems = setQuantityToItems(packageItems[1], false);

  state.currentOrder.packageItems = [standardItems, extraItems];
  setTotalPriceItems(state);
};
export const setQuantityToItems = (packageItems, selected) => {
  packageItems.forEach((item, i) => {
    packageItems[i].selected = selected;
    packageItems[i].quantity = 1;
    let total = parseInt(packageItems[i].quantity) * parseFloat(packageItems[i].price_awg).toFixed(2);
    packageItems[i].total_item_price = total.toFixed(2);
  });

  return packageItems;
};
export const updateQuantityTotal = (state, quantity) => {
  for (var n = 0; n < state.currentOrder.packageItems.length; n++) {
    for (var i = 0; i < state.currentOrder.packageItems[n].length; i++) {
      let totalPrice = state.currentOrder.packageItems[n][i].field_price_awg * state.currentOrder.packageItems[n][i].quantity;
      state.currentOrder.packageItems[n][i].total_item_price = totalPrice.toFixed(2);
    }
  }
  setTotalPriceItems(state);
};
export const setPackageItemSelectedAll = (state, packageTypeIndex) => {
  state.currentOrder.selectAll[packageTypeIndex] = !state.currentOrder.selectAll[packageTypeIndex];
  state.currentOrder.packageItems[packageTypeIndex].forEach((_, index) => {
    setPackageItemSelected(state, { e: state.currentOrder.selectAll[packageTypeIndex], type: packageTypeIndex, index: index });
  });
};
export const setPackageItemSelected = (state, data) => {
  state.currentOrder.packageItems[data.type][data.index].selected = data.e;
  setTotalPriceItems(state);
};
export const setTotalPriceItems = state => {
  let total = [0, 0, 0];

  for (var i = 0; i < state.currentOrder.packageItems.length; i++) {
    for (var n = 0; n < state.currentOrder.packageItems[i].length; n++) {
      if (state.currentOrder.packageItems[i][n].selected === true) {
        total[i] = total[i] + parseFloat(state.currentOrder.packageItems[i][n].total_item_price);
      }
    }
    total[i] = total[i].toFixed(2);
  }
  total[2] = parseFloat(total[0]) + parseFloat(total[1]);
  total[2] = total[2].toFixed(2);
  state.currentOrder.packageItemTotals = total;
};
export const setPackageSpecialRequests = (state, data) => {
  state.currentOrder.packageSpecialRequests = data;
};
export const setDifferentAddressSampling = (state, data) => {
  state.currentOrder.differentAddressSampling = data;
};
export const setSampleDoneByWebLab = (state, data) => {
  state.currentOrder.sampleDoneByWebLab = data;
};
export const setServiceAddress = (state, data) => {
  state.currentOrder.serviceAddress = data;
};
export const setSamplePoint = (state, data) => {
  state.currentOrder.samplePoint = data;
};
export const setOrderMailFromUser = (state, data) => {
  state.ordersMadeSelected.mail = data;
};
export const setMeasurementUnits = (state, data) => {
  state.measurementUnits = data;
};

//orders data create----------------------------------
export const setPackgeOrder = (state, payload) => {
  //set sampling location to address if not set
  if (state.currentOrder.serviceAddress == null) state.currentOrder.serviceAddress = state.auth.userData.company_address;
  //set name for package
  state.currentOrder.name = payload.currentOrder.selectedPackage.package_name + ' ' + numberPad(+payload.index + 1, 3);
  //save package to array, also used in edit
  let currentOrder = JSON.parse(JSON.stringify(payload.currentOrder));
  state.orderPackages.splice(payload.index, 1, currentOrder);
  setEditMode(state, false);
  setEditIndex(state, null);
  resetCurrentOrder(state);
};
export const duplicatePackage = (state, index) => {
  let duplicatedPackage = JSON.parse(JSON.stringify(state.orderPackages[index]));
  //copy copoy new values to duplicated package
  duplicatedPackage.differentAddressSampling = state.currentOrder.differentAddressSampling;
  duplicatedPackage.serviceAddress = state.currentOrder.serviceAddress;
  duplicatedPackage.samplePoint = state.currentOrder.samplePoint;
  duplicatedPackage.duplicateOf = duplicatedPackage.name;
  duplicatedPackage.name = duplicatedPackage.selectedPackage.package_name + ' ' + numberPad(state.orderPackages.length + 1, 3);
  //push to array
  state.orderPackages.push(duplicatedPackage);
  calculateTotalOrdersPrice(state);
  resetCurrentOrder(state);
};
export const cancelPackageEdit = state => {
  setEditMode(state, false);
  setEditIndex(state, null);
};
export const removePackageFromOrder = (state, index) => {
  let packagesToRemove = [index];
  //remove duplicates
  state.orderPackages.forEach((package1, i) => {
    if (package1.duplicateOf == state.orderPackages[index].name) {
      packagesToRemove.push(i);
    }
  });

  //removed selected package
  state.orderPackages = state.orderPackages.filter((pack, i) => {
    if (!packagesToRemove.includes(i)) return pack;
  });

  //if all packages removed go bakc to start
  if (state.orderPackages.length === 0) {
    updateCurrentStep(state, 1);
    setWizardAnimationClass(state, 'wizard-back');
    clearWizard(state);
  }
};
export const editOrder = (state, index) => {
  state.currentOrder = JSON.parse(JSON.stringify(state.orderPackages[index]));
  setEditMode(state, true);
  setEditIndex(state, index);
  updateCurrentStep(state, 1);
};
export const setEditMode = (state, mode) => {
  state.edit.mode = mode;
};
export const setEditIndex = (state, index) => {
  state.edit.index = index;
};
export const clearOrderPackages = state => {
  state.orderPackages = [];
};
export const calculateTotalOrdersPrice = state => {
  let orderPackages = state.orderPackages;
  let totalPrice = orderPackages.reduce((sum, order) => {
    return sum + parseFloat(order.packageItemTotals[2]);
  }, 0);
  state.orderData.orderTotalPrice = totalPrice.toFixed(2);

  let orderTotalPrice = parseFloat(state.orderData.orderTotalPrice) + state.orderData.adminCosts;
  let bboPrice = orderTotalPrice * (state.taxes.bbo / 100);
  let bazvPrice = orderTotalPrice * (state.taxes.bazv / 100);
  let bavpPrice = orderTotalPrice * (state.taxes.bavp / 100);
  state.orderData.orderTotalPriceAdminCosts = orderTotalPrice.toFixed(2);
  state.orderData.orderTotalPriceBBO = bboPrice.toFixed(2);
  state.orderData.orderTotalPriceBAZV = bazvPrice.toFixed(2);
  state.orderData.orderTotalPriceBAVP = bavpPrice.toFixed(2);
  //let totalWithTaxes = orderTotalPrice + bboPrice + bazvPrice + bavpPrice;
  //state.orderData.orderTotalPriceWithTaxes = totalWithTaxes.toFixed(2);
  state.orderData.orderTotalPriceWithTaxes = orderTotalPrice.toFixed(2);
};
export const duplicateOrder = state => {
  state.currentOrder = state.ordersMadeSelected;
  resetOrderPackages(state);
  state.orderPackages = state.ordersMadeSelected.order_packages;
  state.currentStep = 4;
};

export const resetCurrentOrder = (state, scaleAnimation = false) => {
  state.currentOrder = JSON.parse(JSON.stringify(state.cachedData.currentOrder));
  if (scaleAnimation) setWizardAnimationClass(state, 'scale');
  if (state.orderPackages.length > 0) state.currentStep = 4;
  else state.currentStep = 1;
};
export const resetOrderPackages = state => {
  state.orderPackages = [];
  state.currentStep = 1;
};
export const SET_INVOICE_DATA = (state, data) => {
  state.orderData.adminCosts = parseFloat(data.admin_costs);
  state.orderData.orderInvoiceDueDays = parseInt(data.invoice_due_days);
  state.invoiceData = {};
  data.invoice_contact_info.forEach(item => {
    state.invoiceData[item.type] = item.info;
  });

  state.termsAndConditions = data.terms_and_conditions;
};

//wizard----------------------------------
export const updateCurrentStep = (state, step) => {
  let scrollTop =
    window.pageYOffset !== undefined ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  let animateTime = 0;
  if (scrollTop > 100) animateTime = 200;
  jQuery('html,body').animate({ scrollTop: 0 }, animateTime, function() {
    state.currentStep = step;
  });
};
export const enableNextButton = (state, action) => {
  if (action) {
    state.wizardNav.nextButtonEnabled = true;
  } else state.wizardNav.nextButtonEnabled = false;
};
export const enableBackButton = (state, action) => {
  if (action) {
    state.wizardNav.backButtonEnabled = true;
  } else state.wizardNav.backButtonEnabled = false;
};
export const nextButtonText = (state, text) => {
  if (text === 'reset') state.wizardNav.nextButtonText = 'Next <i class="glyphicon glyphicon-chevron-right"></i>';
  else state.wizardNav.nextButtonText = text;
};
export const backButtonText = (state, text) => {
  if (text === 'reset') state.wizardNav.backButtonText = '<i class="glyphicon glyphicon-chevron-left"></i> Back';
  else state.wizardNav.backButtonText = text;
};
export const clearWizard = state => {
  state.currentOrder = JSON.parse(JSON.stringify(state.cachedData.currentOrder));
};
export const setWizardAnimationClass = (state, animationClass) => {
  state.wizardNav.animationClass = animationClass;
};

//user data----------------------------------
export const setUserData = (state, userData) => {
  let roles = userData.user_roles;
  for (let index = 0; index < roles.length; index++) {
    let role = roles[index];
    if (role.name === 'lab_admin') {
      userData.isDashboardAdmin = true;
      break;
    } else {
      userData.isDashboardAdmin = false;
    }
  }
  for (let index = 0; index < roles.length; index++) {
    let role = roles[index];
    if (role.name === 'admin') {
      userData.isAdmin = true;
      break;
    } else {
      userData.isAdmin = false;
    }
  }

  for (let index = 0; index < roles.length; index++) {
    let role = roles[index];
    if (role.name === 'client') {
      userData.isClient = true;
      break;
    } else {
      userData.isClient = false;
    }
  }

  for (let index = 0; index < roles.length; index++) {
    let role = roles[index];
    if (role.name === 'accounting') {
      userData.isAccounting = true;
      break;
    } else {
      userData.isAccounting = false;
    }
  }
  state.auth.userData = userData;
};
export const setSelectedClientIdByAdmin = (state, id) => {
  state.orderData.selectedClientIdByAdmin = id;
};

export const SET_USER_LOGGED_IN = (state, value) => {
  localStorage.setItem('my_lab_logged_in', value);
};

export const LOGOUT = state => {
  localStorage.removeItem('my_lab_logged_in');
  state.auth.userData = {};
  state.auth.accessToken = null;
};

//Orders made ---------------------------------------
export const setOrdersMadeUser = (state, data) => {
  state.ordersMadeUser = data;
  for (var index = 0; index < state.ordersMadeUser.results.length; index++) {
    state.ordersMadeUser.results[index].order_data = JSON.parse(data.results[index].order_data);
  }
};
export const getOrdersMade = (state, ordersData) => {
  state.ordersMade = ordersData;
};
export const clearOrdersMade = (state, data) => {
  state.ordersMade = [];
};
export const setOrdersStatesCount = (state, data) => {
  let dataClean = [];
  if (state.auth.userData.isClient) {
    dataClean = data.filter(orderState => {
      return orderState.slug === 'quote_ready' || orderState.slug === 'approval_date_time' || orderState.slug === 'invoice_ready';
    });
  } else if (state.auth.userData.isDashboardAdmin) {
    dataClean = data.filter(orderState => {
      return orderState.slug === 'order_created' || orderState.slug === 'quote_approved' || orderState.slug === 'date_time_approved';
    });
  } else if (state.auth.userData.isAccounting) {
    dataClean = data.filter(orderState => {
      return orderState.slug === 'quote_approved';
    });
  }

  state.ordersStateCount = dataClean;
};
export const selectOrderMade = (state, data) => {
  state.ordersMadeSelectedData.orderId = data.id;
  state.ordersMadeSelected = data;
  //state.ordersMadeSelected.field_order_state_tax = JSON.parse(data.field_order_state_tax);
};
export const selectOrderFromCalendar = (state, data) => {
  state.ordersMadeSelectedData.selectedFromCalendar = data;
};
export const unselectOrderMade = state => {
  if (state.ordersMadeSelectedData.edit) cancelEditSelectedOrder(state);
  state.ordersMadeSelectedData.orderId = null;
  state.ordersMadeSelected = {};
};
export const editSelectedOrder = state => {
  state.ordersMadeSelectedCached = JSON.parse(JSON.stringify(state.ordersMadeSelected));
  state.ordersMadeSelectedData.edit = true;
};
export const editSelectedOrderAccounting = state => {
  state.ordersMadeSelectedCached = JSON.parse(JSON.stringify(state.ordersMadeSelected));
  state.ordersMadeSelectedData.editAccounting = true;
};
export const cancelEditSelectedOrder = state => {
  state.ordersMadeSelected = state.ordersMadeSelectedCached;
  state.ordersMadeSelectedCached = {};
  state.ordersMadeSelectedData.edit = false;
  state.ordersMadeSelectedData.editAccounting = false;
};
export const setOrderSates = (state, data) => {
  state.orderStates = data;
};
export const updateOrderState = (state, data) => {
  let name = state.orderStates.filter(orderState => {
    return orderState.id == data;
  });
  state.ordersMadeSelected.order_state_id = parseInt(data);
  state.ordersMadeSelected.order_state_name = name[0].name;
};
export const updateAssignedWorker = (state, data) => {
  state.ordersMadeSelected.assigned_worker_data = data;
  state.ordersMadeSelected.assigned_worker_id = data.id;
};
export const clearAssignedWorker = state => {
  state.ordersMadeSelected.assigned_worker_data = null;
  state.ordersMadeSelected.assigned_worker_id = null;
};
export const updateServiceDate = (state, data) => {
  state.ordersMadeSelected.service_date_time = data.date;
  state.ordersMadeSelected.service_date_time_formatted = data.date_formatted;
};
export const orderSaved = (state, data) => {
  state.ordersMadeSelectedCached = {};
  state.ordersMadeSelected = data.order;
  state.ordersMadeSelectedData.edit = false;
  state.ordersMadeSelectedData.editAccounting = false;
};
export const setPackageItemSelectedOrderMade = (state, data) => {
  state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[data.type][data.itemIndex].selected = data.e;
  setTotalPriceItemSelectedOrderMade(state, data);
};
export const setTotalPriceItemSelectedOrderMade = (state, data) => {
  let total = [0, 0, 0];
  for (var i = 0; i < state.ordersMadeSelected.order_packages[data.packageIndex].packageItems.length; i++) {
    for (var n = 0; n < state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[i].length; n++) {
      if (state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[i][n].selected === true) {
        total[i] = total[i] + parseFloat(state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[i][n].total_item_price);
      }
    }
    total[i] = total[i].toFixed(2);
  }
  total[2] = parseFloat(total[0]) + parseFloat(total[1]);
  total[2] = total[2].toFixed(2);
  state.ordersMadeSelected.order_packages[data.packageIndex].packageItemTotals = total;

  calculateTotalPriceOrderSelectedOrderMade(state);
};
export const updateQuantityTotalSelectedOrderMade = (state, data) => {
  //console.log(data); return;
  for (var n = 0; n < state.ordersMadeSelected.order_packages[data.packageIndex].packageItems.length; n++) {
    for (var i = 0; i < state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[n].length; i++) {
      state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[n][i].total_item_price = (
        state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[n][i].field_price_awg *
        state.ordersMadeSelected.order_packages[data.packageIndex].packageItems[n][i].quantity
      ).toFixed(2);
    }
  }
  setTotalPriceItemSelectedOrderMade(state, data);
};
export const calculateTotalPriceOrderSelectedOrderMade = state => {
  let orderPackages = state.ordersMadeSelected.order_packages;
  let additionalCosts;
  let total;

  calculateTotalAdditionalCosts(state);

  //package items
  let totalPricePackages = orderPackages.reduce((sum, order) => {
    return sum + parseFloat(order.packageItemTotals[2]);
  }, 0);

  //additional costs
  if (state.ordersMadeSelected.order_data.additionalCosts) {
    additionalCosts = state.ordersMadeSelected.order_data.additionalCosts;
    let totalPriceAdditionalCosts = additionalCosts.reduce((sum, cost) => {
      if (!cost.cost) {
        cost.cost = 0;
      }
      return sum + parseFloat(cost.cost);
    }, 0);
    total = totalPricePackages + totalPriceAdditionalCosts;
    state.ordersMadeSelected.order_data.orderTotalPrice = total.toFixed(2);
  } else {
    total = totalPricePackages;
    state.ordersMadeSelected.order_data.orderTotalPrice = total.toFixed(2);
  }
  setOrderTotalTaxes(state);
};
export const setOrderTotalTaxes = state => {
  let orderTotalPrice = parseFloat(state.ordersMadeSelected.order_data.orderTotalPrice) + state.ordersMadeSelected.order_data.adminCosts;
  let bboPrice = orderTotalPrice * (state.taxes.bbo / 100);
  let bazvPrice = orderTotalPrice * (state.taxes.bazv / 100);
  let bavpPrice = orderTotalPrice * (state.taxes.bavp / 100);

  state.ordersMadeSelected.order_data.orderTotalPriceBBO = bboPrice.toFixed(2);
  state.ordersMadeSelected.order_data.orderTotalPriceBAZV = bazvPrice.toFixed(2);
  state.ordersMadeSelected.order_data.orderTotalPriceBAVP = bavpPrice.toFixed(2);
  let totalWithTaxes = orderTotalPrice + bboPrice + bazvPrice + bavpPrice;
  state.ordersMadeSelected.order_data.orderTotalPriceWithTaxes = orderTotalPrice.toFixed(2);
};
export const setServiceAddressSelectedOrderMade = (state, data) => {
  state.ordersMadeSelected.order_packages[data.packageIndex].serviceAddress = data.serviceAddress;
};
export const setSamplePointSelectedOrderMade = (state, data) => {
  state.ordersMadeSelected.order_packages[data.packageIndex].samplePoint = data.samplePoint;
};
export const setSampleDoneByWebLabSelectedOrderMade = (state, data) => {
  state.ordersMadeSelected.order_packages[data.packageIndex].sampleDoneByWebLab = data.sampleDoneByWebLab;
};
export const setPackageSpecialRequestsOrderMade = (state, data) => {
  state.ordersMadeSelected.order_packages[data.packageIndex].packageSpecialRequests = data.request;
};
export const removeAdditionalCost = (state, index) => {
  state.ordersMadeSelected.order_data.additionalCosts.splice(index, 1);
  calculateTotalPriceOrderSelectedOrderMade(state);
};
export const setDescription = (state, data) => {
  state.ordersMadeSelected.order_data.additionalCosts[data.index].description = data.description;
};
export const setHourRate = (state, data) => {
  let cost;
  let hours = state.ordersMadeSelected.order_data.additionalCosts[data.index].hours;
  let hourRate = data.hourRate;

  if (hours && hourRate) {
    cost = hours * hourRate;
    data.cost = cost;
  } else data.cost = null;
  setCost(state, data);
  state.ordersMadeSelected.order_data.additionalCosts[data.index].hourRate = data.hourRate;
};
export const setHours = (state, data) => {
  let cost;
  let hours = data.hours;
  let hourRate = state.ordersMadeSelected.order_data.additionalCosts[data.index].hourRate;

  if (hours && hourRate) {
    cost = hours * hourRate;
    data.cost = cost;
  } else data.cost = null;
  setCost(state, data);
  state.ordersMadeSelected.order_data.additionalCosts[data.index].hours = data.hours;
};
export const setCost = (state, data) => {
  let cost;
  if (data.cost) {
    cost = data.cost.toFixed(2);
  } else cost = null;
  state.ordersMadeSelected.order_data.additionalCosts[data.index].cost = cost;
  calculateTotalPriceOrderSelectedOrderMade(state);
};
export const calculateTotalAdditionalCosts = state => {
  let total = state.ordersMadeSelected.order_data.additionalCosts.reduce((sum, cost) => {
    if (cost.cost) return sum + parseFloat(cost.cost);
    else return sum + 0;
  }, 0);
  state.ordersMadeSelected.order_data.additionalCostsTotal = total.toFixed(2);
};
export const addCost = (state, data) => {
  let additionalCost = {
    description: null,
    hourRate: null,
    hours: null,
    cost: null
  };
  state.ordersMadeSelected.order_data.additionalCosts.push(additionalCost);
};
export const setResultFile = (state, data) => {
  state.ordersMadeSelected.result_files = data;
};
export const setInvoiceFile = (state, data) => {
  state.ordersMadeSelected.invoice_files = data;
};
export const setProofOfPaymentFile = (state, data) => {
  state.ordersMadeSelected.proof_of_payment_files = data;
};
export const unselectResultFile = state => {
  state.resultsFile.file = null;
  state.resultsFile.fileName = null;
};
export const setResultFileToUpload = (state, data) => {
  state.resultsFileToUpload.file = data.file;
  state.resultsFileToUpload.fileName = data.fileName;
};
export const setInvoiceDate = (state, data) => {
  state.ordersMadeSelected.order_data.orderInvoiceDate.normal = data.dueDate;
  state.ordersMadeSelected.order_data.orderInvoiceDate.formatted = data.formatted;
};
export const setDueDate = (state, data) => {
  state.ordersMadeSelected.order_data.orderInvoiceDueDate = data;
};
export const setDueDateDays = (state, data) => {
  state.ordersMadeSelected.order_data.orderInvoiceDueDays = data;
};
export const checkBoxClickedEmail = (state, data) => {
  state.ordersMadeSelected.order_data.stateChangeMail = data;
};
export const checkBoxClickedAssWorkerEmail = (state, data) => {
  state.ordersMadeSelected.order_data.sentEmailAssWorker = data;
};
export const setClientRejectedDateTime = (state, value) => {
  state.ordersMadeSelected.order_data.clientRejectedDateTime = value;
};
export const setSearchFilterQuery = (state, data) => {
  state.ordersMadeFilters.searchQuery = data;
};
export const setStatusFilterQuery = (state, data) => {
  state.ordersMadeFilters.status = data;
};
export const setDashboardAdmins = (state, data) => {
  state.dashboardAdmins = data;
};
export const EXPORT_ORDERS_LIST_EXCEL = (state, data) => {
  state.ordersExport = data;
};
export const setOrderComment = (state, data) => {
  state.ordersMadeSelected.order_data.orderComment = data;
};

//Pager ---------------------------------------
export const setPager = state => {
  let count = state.ordersMade.total;
  let displayCount = state.pager.pagerDisplayCount;
  let remainderLinks = Math.round(parseInt(count) % parseInt(displayCount));
  let lastPagerLink = 0;

  if (remainderLinks > 0) lastPagerLink = 1;
  state.pager.pagerLinks = Math.floor(parseFloat(count) / displayCount) + lastPagerLink;

  //state.pagerDisplayCount;
};
export const updatePager = (state, page) => {
  state.pager.currentPage = page;
};
export const setPagerAnimationClass = (state, animationClass) => {
  state.pager.animationClass = animationClass;
};

//browser-------------------------------------
export const setBrowserData = (state, browserObj) => {
  state.browser.name = browserObj.name;
  state.browser.version = browserObj.version;
};
