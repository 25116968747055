import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  { path: '/', name: 'Home', redirect: { name: 'CreateOrder' } },
  {
    path: '/create-order',
    name: 'CreateOrder',
    component: () => import('../components/pages/create-order/CreateOrder.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/orders-user/:orderNumberUser?',
  //   name: 'OrdersUser',
  //   component: () => import('../components/pages/orders-user/OrdersUser.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/orders',
    name: 'OrdersAll',
    component: () => import('../components/pages/orders/OrdersList/OrdersListView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders/:orderNumber',
    name: 'OrdersSelected',
    component: () => import('../components/pages/orders/OrderSelected/OrderSelected.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order-calendar',
    name: 'OrderCalendar',
    component: () => import('../components/pages/orders-calendar/OrdersCalendar.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../components/pages/user-page/User.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lab-packages',
    name: 'LabPackagesPage',
    component: () => import('../components/pages/lab-packages/LabPackages.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lab-packages/:packageCode?',
    name: 'SelectedPackage',
    component: () => import('../components/pages/lab-packages/selected-package/SelectedPackage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    component: () => import('../components/pages/settings-page/SettingsPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage-users',
    name: 'UserManagementPage',
    component: () => import('../components/pages/user-management/user-management-list/UserManagementList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage-users/:userId',
    name: 'ManageUserPage',
    component: () => import('../components/pages/user-management/manage-user/ManageUserPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../components/pages/user-management/manage-user/ManageUserPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-not-verified',
    name: 'UserNotVerified',
    component: () => import('../components/pages/auth/UserNotVerified.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/pages/auth/LoginPage.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../components/pages/auth/RegisterPage.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../components/pages/auth/ForgotPasswordPage.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../components/pages/auth/ResetPasswordPage.vue')
  },
  {
    path: '/account-activation',
    name: 'AccountActivation',
    component: () => import('../components/pages/auth/AccountActivationPage.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     while (Object.keys(store.getters.userData).length === 0 && store.getters.userData.constructor === Object) {
//       await sleep(100);
//     }

//     if (store.getters.userData) {
//       next();
//     } else {
//       window.location.href = '/login';
//     }
//   } else {
//     next();
//   }
// });

export default router;
