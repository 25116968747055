<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      latestVersion: null,
      updating: false,
      registration: null
    };
  },
  mounted() {
    //check for new version
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
      document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    }
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;

      this.$snotify.warning('Update available, please click below to update', '', {
        timeout: 60000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerBottom',
        buttons: [
          {
            text: 'Update',
            action: toast => {
              this.$snotify.remove(toast.id);
              this.update();
            }
          }
        ]
      });
    },

    update() {
      this.updating = true;

      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        window.location.reload();
      }

      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  }
};
</script>

<style></style>
