import axios from 'axios';
import router from '@/router';
import store from '@/store';

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  response => {
    return response;
  },

  error => {
    if (error.response.status === 401 && error.response.data.message == 'Unauthenticated.') {
      store.commit('LOGOUT');
      location.reload();
      //window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

//-------------------------------------------------------------------------------------------------

export const GET_CSRF_TOKEN = async baseUrlApi => {
  return axios
    .get(`${baseUrlApi}/sanctum/csrf-cookie`)
    .then(res => {
      if (res.status == 200 || res.status == 204) {
        return true;
      } else return false;
    })
    .catch(error => {
      console.log(error);
    });
};
export const LOGIN = async ({ state, commit }, payload) => {
  await GET_CSRF_TOKEN(state.config.baseUrlApi);
  return axios
    .post(`${state.config.baseUrlApi}/api/login`, JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      if (res.status == 200) {
        commit('SET_USER_LOGGED_IN', true);
        commit('setUserData', res.data.user);
        if (!res.data.user.email_verified_at && window.location.pathname != '/user-not-verified') {
          router.push('/user-not-verified');
        } else {
          return res.data;
        }
      }
    })
    .catch(e => {
      console.error(e);
      reject('error fetching data');
      //return e;
    });
};
export const LOGOUT = async ({ state, commit }) => {
  commit('setTransLoader', true);
  return axios
    .post(`${state.config.baseUrlApi}/api/logout`, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then(res => {
      if (res.status == 200) {
        commit('LOGOUT');
      }
    })
    .catch(e => {
      console.error(e);
      reject('error fetching data');
      //return e;
    })
    .finally(() => commit('setTransLoader', false));
};
export const REGISTER = async ({ state }, payload) => {
  await GET_CSRF_TOKEN(state.config.baseUrlApi);
  return await axios
    .post(`${state.config.baseUrlApi}/api/register`, JSON.stringify(payload), { headers: { 'Content-Type': 'application/json' } })
    .then(res => {
      if (res.status == 200 || res.status == 201) {
        return res.data;
      }
    })
    .catch(e => {
      console.error(e);
      //reject('error fetching data');
      return e;
    });
};
export const ACTIVATE_ACCOUNT = async ({}, url) => {
  return await axios
    .get(url)
    .then(res => {
      if (res.status == 200 || res.status == 201) {
        return res.data;
      }
    })
    .catch(e => {
      console.error(e);
      //reject('error fetching data');
      return e;
    });
};
export const FORGOT_PASSWORD = async ({ state, commit }, payload) => {
  await GET_CSRF_TOKEN(state.config.baseUrlApi);
  return await axios
    .post(`${state.config.baseUrlApi}/api/forgot-password`, payload)
    .then(res => {
      if (res.status == 200 || res.status == 201) {
        return res.data;
      }
    })
    .catch(e => {
      console.error(e);
      //reject('error fetching data');
      return e;
    });
};
export const RESET_PASSWORD = async ({ state, commit }, payload) => {
  await GET_CSRF_TOKEN(state.config.baseUrlApi);
  return await axios
    .post(`${state.config.baseUrlApi}/api/reset-password`, payload)
    .then(res => {
      if (res.status == 200 || res.status == 201) {
        return res.data;
      }
    })
    .catch(e => {
      console.error(e);
      //reject('error fetching data');
      return e;
    });
};

//packages------------------------------------------------------------------------------------------------
export const getPackages = ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/packages`)
      .then(response => {
        commit('setPackages', response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('error fetching data');
      });
  });
};
export const getPackageItems = ({ commit, state }, packageCode) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/package/${packageCode}`)
      .then(response => {
        commit('setPackageItems', [response.data.standard_tests, response.data.extra_tests]);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('error fetching data');
      });
  });
};
export const getSelectedPackage = ({ state }, packageCode) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/package/${packageCode}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('error fetching data');
      });
  });
};
export const getQuantityTotal = ({ commit }, quantity) => {
  commit('updateQuantityTotal', quantity);
};
export const updatePackage = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/update-package`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
export const removePackageFromOrder = ({ commit }, index) => {
  commit('removePackageFromOrder', index);
};
export const updatePackagesSortOrder = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/sort-packages`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('error fetching data');
      });
  });
};
export const createPackage = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/create-package`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('error fetching data');
      });
  });
};
export const deletePackage = ({ state }, id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${state.config.baseUrlApi}/api/delete-package/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('error fetching data');
      });
  });
};

//-------------------------------------------------------------------------------------------------
//orders create / settings
export const createOrder = ({ commit, state }, order) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/create-order`, order)
      .then(response => {
        //commit('orderCreated', response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject(error.response?.data.message ?? 'Error creating order, please try again.');
        console.log(error);
      });
  });
};
export const setPackgeOrder = ({ commit }, payload) => {
  commit('setPackgeOrder', payload);
};
export const getInvoiceData = ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/settings`)
      .then(response => {
        commit('SET_INVOICE_DATA', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const updateSettings = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${state.config.baseUrlApi}/api/settings`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

//USERS -------------------------------------------------------------------------------------------------
//user data
export const getUserData = ({ state, commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/current-user`)
      .then(response => {
        commit('setUserData', response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('Failed to fetch data');
      });
  });
};
export const getUsers = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/get-users?q=${payload.searchQuery}&page=${payload.currentPage}&page_range=${payload.pagerDisplayCount}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getSingleUser = ({ state }, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/get-users/${userId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const editUser = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${state.config.baseUrlApi}/api/edit-user`, payload)
      .then(response => {
        resolve(response.data);
        return response.data;
      })
      .catch(error => {
        reject(error);
        return error;
      });
  });
};
export const sentVerificationMail = ({ state, commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/email/verification-notification`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('');
      });
  });
};
export const sentVerificationMailAdmin = ({ state }, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/email/verification-notification-admin`, { userId })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('');
      });
  });
};
export const assignRole = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${state.config.baseUrlApi}/api/assign-role`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        console.log(error);
        reject('');
      });
  });
};

//Wizard-------------------------------------------------------------------------------------------------
export const clearWizard = ({ commit }) => {
  commit('clearWizard');
};
//steps
export const setCurrentStep = ({ commit }, step) => {
  commit('updateCurrentStep', step);
};

//Orders made ---------------------------------------
export const getOrdersMade = ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${state.config.baseUrlApi}/api/get-orders?q=${state.ordersMadeFilters.searchQuery}&order_state=${state.ordersMadeFilters.status}&page=${state.pager.currentPage}&page_range=${state.pager.pagerDisplayCount}`
      )
      .then(response => {
        commit('getOrdersMade', response.data);
        commit('updatePager', state.pager.currentPage);
        setPager({ commit });
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getOrdersMadeUser = ({ commit, state }) => {
  console.log('get orders user');
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${state.config.baseUrlApi}/api/get-orders?q=${state.ordersMadeFilters.searchQuery}&order_state=${state.ordersMadeFilters.status}&page=${state.pager.currentPage}&page_range=${state.pager.pagerDisplayCount}`
      )
      .then(response => {
        commit('getOrdersMade', response.data);
        commit('updatePager', state.pager.currentPage);
        setPager({ commit });
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getSelectedOrder = async ({ commit, state }, orderNumber) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/get-order/${orderNumber}`)
      .then(response => {
        commit('selectOrderMade', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getOrdersCalendar = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/get-orders-calendar?user=${payload.user}&start=${payload.start}&end=${payload.end}`)
      .then(response => {
        commit('getOrdersMade', response.data);
        commit('updatePager', state.pager.currentPage);
        setPager({ commit });
        return resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const getOrdersStatesCount = ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/order-counts`)
      .then(response => {
        commit('setOrdersStatesCount', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const selectOrderMade = ({ commit }, data) => {
  commit('selectOrderMade', data);
};
export const unselectOrderMade = ({ commit }) => {
  commit('unselectOrderMade');
};
export const editSelectedOrder = ({ commit }) => {
  commit('editSelectedOrder');
};
export const editSelectedOrderAccounting = ({ commit }) => {
  commit('editSelectedOrderAccounting');
};
export const cancelEditSelectedOrder = ({ commit }) => {
  commit('cancelEditSelectedOrder');
};
export const getOrderStates = async ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/order-states`)
      .then(response => {
        commit('setOrderSates', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const updateOrderState = ({ commit }, data) => {
  commit('updateOrderState', data);
};
export const updateAssignedWorker = ({ commit }, data) => {
  commit('updateAssignedWorker', data);
};
export const updateServiceDate = ({ commit }, data) => {
  commit('updateServiceDate', data);
};
export const saveSelectedOrder = ({ commit, state }, order) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/update-order`, order)
      .then(response => {
        commit('orderSaved', { order: response.data });
        resolve(response.data);
      })
      .catch(error => {
        reject('Error creating order, please try again.');
        console.log(error);
      });
  });
};
export const uploadProofOfPaymentFile = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/upload-proof-of-payment-file`, payload)
      .then(response => {
        commit('setProofOfPaymentFile', response.data.proof_of_payment_files);
        resolve(response.data);
      })
      .catch(error => {
        reject('Error uploading file, please try again.');
        console.log(error);
      });
  });
};
export const uploadResultFile = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/upload-result-file`, payload)
      .then(response => {
        commit('setResultFile', response.data.result_files);
        resolve(response.data);
      })
      .catch(error => {
        reject('Error uploading file, please try again.');
        console.log(error);
      });
  });
};
export const uploadInvoiceFile = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/upload-invoice-file`, payload)
      .then(response => {
        commit('setInvoiceFile', response.data.invoice_files);
        resolve(response.data);
      })
      .catch(error => {
        reject('Error uploading file, please try again.');
        console.log(error);
      });
  });
};

export const saveSelectedOrderUser = ({ commit, state }, order) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/update-order-user`, order)
      .then(response => {
        commit('orderSaved', { order: response.data });
        resolve(response.data);
      })
      .catch(error => {
        reject('Error creating order, please try again.');
        console.log(error);
      });
  });
};
export const cancelOrderUser = ({ commit, state }, order) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/cancel-order-user`, order)
      .then(response => {
        resolve(response.data);
        return response.data;
      })
      .catch(error => {
        reject('Error canceling order, please try again.');
        console.log(error);
      });
  });
};
export const clientRejectedDateTime = ({ commit, state }, order) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/reject-date-time`, order)
      .then(response => {
        commit('orderSaved', { order: response.data });
        resolve(response.data);
      })
      .catch(error => {
        reject('Error creating order, please try again.');
        console.log(error);
      });
  });
};
export const setPackageItemSelectedOrderMade = ({ commit }, data) => {
  commit('setPackageItemSelectedOrderMade', data);
};
export const getQuantityTotalSelectedOrderMade = ({ commit }, data) => {
  commit('updateQuantityTotalSelectedOrderMade', data);
};
export const setServiceAddressSelectedOrderMade = ({ commit }, data) => {
  commit('setServiceAddressSelectedOrderMade', data);
};
export const setSamplePointSelectedOrderMade = ({ commit }, data) => {
  commit('setSamplePointSelectedOrderMade', data);
};
export const setSampleDoneByWebLabSelectedOrderMade = ({ commit }, data) => {
  commit('setSampleDoneByWebLabSelectedOrderMade', data);
};
export const setPackageSpecialRequestsOrderMade = ({ commit }, data) => {
  commit('setPackageSpecialRequestsOrderMade', data);
};
export const removeAdditionalCost = ({ commit }, index) => {
  commit('removeAdditionalCost', index);
};
export const setDescription = ({ commit }, data) => {
  commit('setDescription', data);
};
export const setHourRate = ({ commit }, data) => {
  commit('setHourRate', data);
};
export const setHours = ({ commit }, data) => {
  commit('setHours', data);
};
export const setCost = ({ commit }, data) => {
  commit('setCost', data);
};
export const addCost = ({ commit }) => {
  commit('addCost');
};
export const deleteFile = ({ commit, state }, payload) => {
  axios.post(`${state.config.baseUrlApi}/api/delete-result-file`, payload).then(data => {
    commit('orderSaved', { order: data.data });
  });
};
// export const deleteInvoiceFile = ({ commit, state }, payload) => {
//   commit('setTransLoader', true);
//   axios
//     .post(`${state.config.baseUrlApi}/api/delete-result-file`, payload)
//     .then(data => {
//       commit('orderSaved', { order: data.data });
//     })
//     .finally(() => commit('setTransLoader', false));
// };
export const getDashboardAdmins = ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/dashboard-admins`)
      .then(response => {
        commit('setDashboardAdmins', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const exportOrderListExcel = ({ commit, state }, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${state.config.baseUrlApi}/api/get-orders-export-excel?start_date=${params.startDate}&end_date=${params.endDate}&order_state=${params.orderStatus}&ass_worker=${params.assignedWorker}&sort_by=${params.sortBy}&sort_direction=${params.sortDirection}&_format=json`
      )
      .then(response => {
        commit('EXPORT_ORDERS_LIST_EXCEL', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

//Units -----------------------------------------
export const getMeasurementUnits = ({ commit, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/measurement-units`)
      .then(response => {
        commit('setMeasurementUnits', response.data);
        resolve(response.data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      });
  });
};
export const editMeasurementUnit = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${state.config.baseUrlApi}/api/edit-measurement-unit`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const addMeasurementUnit = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/add-measurement-unit`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const deleteMeasurementUnit = ({ state }, id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${state.config.baseUrlApi}/api/delete-measurement-unit/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

//Analysis items -----------------------------------------
export const getAnalysisItems = ({ state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/analysis-items`)
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      });
  });
};
export const editAnalysisItem = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${state.config.baseUrlApi}/api/edit-analysis-item`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const addAnalysisItem = ({ state }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${state.config.baseUrlApi}/api/add-analysis-item`, payload)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const deleteAnalysisItem = ({ state }, id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${state.config.baseUrlApi}/api/delete-analysis-item/${id}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
export const searchAnalysisItems = ({ state }, q) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${state.config.baseUrlApi}/api/search-analysis-items/${q}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        console.log(e);
        reject(e);
      });
  });
};

//Pager made ---------------------------------------
export const setPager = ({ commit }) => {
  commit('setPager');
};
export const setPagerAnimationClass = ({ commit }, animationClass) => {
  commit('setPagerAnimationClass', animationClass);
};
