//import Snotify from 'vue-snotify'
import moment from 'moment';
import Vue from 'vue';

export function toFixed(num, fixed) {
  console.log(num, fixed);
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export function displayError(error, retryFunction, _this, param1, param2) {
  if (!param1) {
    param1 = '';
  }
  if (!param2) {
    param2 = '';
  }

  _this.$snotify.error(error, 'Error', {
    timeout: 0,
    position: 'centerTop',
    //html: '<i class="glyphicon glyphicon-refresh"></i>',
    buttons: [
      {
        text: 'Retry',
        action: toast => {
          _this.$snotify.remove(toast.id);
          _this[retryFunction](param1, param2);
        }
      }
    ]
  });
}

export function displayErrorNoRetry(error, _this) {
  _this.$snotify.error(error, 'Error', {
    timeout: 0,
    position: 'centerTop'
  });
}

export function base64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function removeModalFromBody(modalId) {
  if (document.getElementById(modalId)) document.getElementById(modalId).remove();
}

export function formatDateTime(date, utc = false) {
  if (utc) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss')
      .subtract(4, 'hours')
      .format('DD-MMM-YYYY / hh:mm A');
  } else {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY / hh:mm A');
  }
}

export function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  location.search
    .substr(1)
    .split('&')
    .forEach(function(item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export function handleInternetConnectivity() {
  window.addEventListener('offline', () => {
    Vue.prototype.$snotify.error(`No internet`, '', {
      timeout: 0,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      position: 'centerBottom'
    });
  });

  window.addEventListener('online', () => {
    clearAllNotifications();
  });
}

export function clearAllNotifications() {
  if (Vue.prototype.$snotify.notifications.length > 0) {
    Vue.prototype.$snotify.notifications.forEach(notification => {
      Vue.prototype.$snotify.clear(notification.id);
    });
  }
}

export function slugToText(slug) {
  let text = slug.replace('_', ' ');
  return text;
}

export function numberPad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
