import store from '@/store';
import axios from 'axios';

//if token reposone retrieved set default headers fo rnetwork requests
// store.subscribe(mutation => {
//   if (mutation.type == 'SET_ACCESS_TOKEN') {
//     if (mutation.payload) {
//       axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
//     } else {
//       axios.defaults.headers.common['Authorization'] = null;
//     }
//   }
// });
