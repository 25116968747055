//package data -------------------
export const packages = state => {
  return state.packages;
};
export const currentOrder = state => {
  return state.currentOrder;
};
export const selectedPackage = state => {
  return state.currentOrder.selectedPackage;
};
export const packageItems = state => {
  return state.currentOrder.packageItems;
};
export const getTotalPriceItems = state => {
  return state.currentOrder.packageItemTotals;
};
export const getDifferentAddressSampling = state => {
  return state.currentOrder.differentAddressSampling;
};
export const getSampleDoneByWebLab = state => {
  return state.currentOrder.sampleDoneByWebLab;
};
export const getServiceAddress = state => {
  return state.currentOrder.serviceAddress;
};
export const getSamplePoint = state => {
  return state.currentOrder.samplePoint;
};
export const getPackageSpecialRequests = state => {
  return state.currentOrder.packageSpecialRequests;
};
export const termsAndConditions = state => {
  return state.termsAndConditions;
};
export const orderExport = state => {
  return state.ordersExport;
};
export const selectedClientIdByAdmin = state => {
  return state.orderData.selectedClientIdByAdmin;
};

//orders data -------------------
export const getOrderPackages = state => {
  return state.orderPackages;
};
export const getOrderData = state => {
  return state.orderData;
};
export const orders = state => {
  return state.orders;
};
export const getCurrentOrder = state => {
  return state.currentOrder;
};
export const getEditMode = state => {
  return state.edit.mode;
};
export const getEditIndex = state => {
  return state.edit.index;
};
export const adminCosts = state => {
  return state.orderData.adminCosts;
};
export const getOrderTotalPrice = state => {
  return state.orderData.orderTotalPrice;
};

//current step------------------
export const currentStep = state => {
  return state.currentStep;
};

//wizard-------------------------------
export const backButtonEnabled = state => {
  return state.backButtonEnabled;
};
export const nextButtonEnabled = state => {
  return state.nextButtonEnabled;
};
export const getNavButtons = state => {
  return state.wizardNav;
};
export const getwizardAnimationClass = state => {
  return state.wizardNav.animationClass;
};

//user data ---------------------------------------
export const userData = state => {
  return state.auth.userData;
};
export const currency = state => {
  return state.currency;
};
export const isDashboardAdmin = state => {
  return state.auth.userData.isDashboardAdmin;
};
export const isAdmin = state => {
  return state.auth.userData.isAdmin;
};
export const isClient = state => {
  return state.auth.userData.isClient;
};
export const isAccounting = state => {
  return state.auth.userData.isAccounting;
};

//orders made---------------------------------------
export const ordersMadeUser = state => {
  return state.ordersMadeUser;
};
export const ordersMade = state => {
  return state.ordersMade;
};
export const ordersStateCount = state => {
  return state.ordersStateCount;
};
export const ordersMadeSelected = state => {
  return state.ordersMadeSelected;
};
export const ordersMadeSelectedStateId = state => {
  return state.ordersMadeSelected.order_state.id;
};
export const ordersMadeSelectedStateSlug = state => {
  return state.ordersMadeSelected.order_state.slug;
};
export const selectedOrderState = state => {
  return state.ordersMadeSelected.order_state.name;
};
export const showInvoiceData = state => {
  let currentState = state.ordersMadeSelected.field_order_state_tax;
  if (currentState == 28 || currentState == 26) return true;
  else return false;
};
export const ordersMadeSelectedCached = state => {
  return state.ordersMadeSelectedCached;
};
export const ordersMadeSelectedData = state => {
  return state.ordersMadeSelectedData;
};
export const ordersMadeSelectedPackages = state => {
  if (state.ordersMadeSelected.order_packages) return state.ordersMadeSelected.order_packages;
};
export const orderStates = state => {
  return state.orderStates;
};
export const getSelectedOrderData = state => {
  return state.ordersMadeSelected.order_data;
};
// export const stateNameOrderMade = (state) => {
//     let name;
//     state.orderStates.forEach(function(orderState, i) {
//         if(orderState.tid == state.ordersMadeSelected.field_order_state_tax){
//             name = orderState.name;
//         }
//     });
//     return name;
// }
export const selectedStateNameOrderMade = state => {
  let name;
  state.orderStates.forEach(function(orderState, i) {
    if (orderState.tid == state.ordersMadeSelected.field_order_state_tax) {
      name = orderState.name;
    }
  });
  return name;
};
export const additionalCostsSelectedOrderMade = state => {
  if (!state.ordersMadeSelected.order_data) return;
  return state.ordersMadeSelected.order_data.additionalCosts;
};
export const getTotalCostOrder = state => {
  if (!state.ordersMadeSelected.order_data) return;
  return state.ordersMadeSelected.order_data.orderTotalPrice;
};
export const taxes = state => {
  return state.taxes;
};
export const resultsFile = state => {
  return state.resultsFile;
};
export const resultsFileToUpload = state => {
  return state.resultsFileToUpload;
};
export const ordersMadeFilters = state => {
  return state.ordersMadeFilters;
};
export const dashboardAdmins = state => {
  return state.dashboardAdmins;
};

//Pages ---------------------------------------
export const pager = state => {
  return state.pager;
};
export const pagerAnimationClass = state => {
  return state.pager.animationClass;
};

//invoice data ----------------------------------
export const invoiceData = state => {
  return state.invoiceData;
};

//browser data ----------------------------------
export const getBrowserData = state => {
  return state.browser;
};
