import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//pwa
import './registerServiceWorker';

//tooltip
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/src/css/index.scss';
Vue.use(Tooltip);
//notifications
import Snotify from 'vue-snotify';
import 'vue-snotify/styles/material.css';
Vue.use(Snotify);
//select2
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
//import "vue-select/src/scss/vue-select.scss";

//wysiwyg editor
import wysiwyg from 'vue-wysiwyg';
Vue.use(wysiwyg, {}); // config is optional. more below

//global jQuery
// window.jQuery = require('jquery');

//utility classes
import '@/assets/styles/libs/bootstrap-4-utilities.min.css';

require('@/store/subscriber');
Vue.config.productionTip = false;

//set page title
window.document.title = process.env.VUE_APP_PAGE_TITLE;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
